var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c("div", { staticClass: "checklist-edit" }, [
        _c(
          "div",
          { staticClass: "step-box" },
          [
            _c("head-layout", {
              attrs: { "show-icon": false, "head-title": "处理进度" },
            }),
            _c(
              "el-steps",
              {
                staticStyle: {
                  "margin-top": "30px",
                  "align-items": "center",
                  height: "50%",
                },
                attrs: { direction: "vertical", active: _vm.active },
              },
              [
                _c("el-step", { attrs: { title: "上报" } }),
                _c("el-step", { attrs: { title: "评估" } }),
                _c("el-step", { attrs: { title: "整改" } }),
                _c("el-step", { attrs: { title: "验收" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "form-box" }, [
          _vm.prepare
            ? _c(
                "div",
                {},
                [
                  _c("head-layout", {
                    attrs: {
                      "head-title": _vm.headTitle,
                      "head-btn-options": _vm.headBtnOptions,
                    },
                    on: {
                      "head-cancel": _vm.headCancel,
                      "head-save": function ($event) {
                        return _vm.headSave(false)
                      },
                      "head-saveBack": function ($event) {
                        return _vm.headSave(true)
                      },
                    },
                  }),
                  _c("div", { staticClass: "formContentBox" }, [
                    _c("div", { staticClass: "formMain" }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 12px" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "dataForm",
                              attrs: {
                                model: _vm.dataForm,
                                "label-width": "150px",
                                rules: _vm.formType == "view" ? {} : _vm.rules,
                                disabled: _vm.formType == "view",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "事项编号",
                                            prop: "cvCode",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: "",
                                              placeholder: "事项编号自动生成",
                                            },
                                            model: {
                                              value: _vm.dataForm.cvCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "cvCode",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.cvCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "地点",
                                            prop: "location",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                placeholder: "请填写地点",
                                                title: _vm.dataForm.location,
                                              },
                                              model: {
                                                value: _vm.dataForm.location,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm,
                                                    "location",
                                                    $$v
                                                  )
                                                },
                                                expression: "dataForm.location",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "el-icon-map-location",
                                                    staticStyle: {
                                                      width: "54px",
                                                      "text-align": "center",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openMap()
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "报告人",
                                            prop: "reportUserName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请选择报告人",
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.handleUserModeOpen()
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm.reportUserName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "reportUserName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.reportUserName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "报告部门",
                                            prop: "reportDeptName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              title:
                                                _vm.dataForm.reportDeptName,
                                              placeholder: "请选择报告部门",
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.handleDeptModeOpen()
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm.reportDeptName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "reportDeptName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm.reportDeptName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "报告时间",
                                            prop: "reportDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              type: "datetime",
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              placeholder: "请选择报告时间",
                                            },
                                            model: {
                                              value: _vm.dataForm.reportDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "reportDate",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.reportDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "描述",
                                            prop: "cvDesc",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: { minRows: 2 },
                                              "show-word-limit": "",
                                              maxlength: "225",
                                              placeholder: "请填写描述",
                                            },
                                            model: {
                                              value: _vm.dataForm.cvDesc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "cvDesc",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.cvDesc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "事项来源",
                                            prop: "sourceType",
                                          },
                                        },
                                        [
                                          _c("avue-select", {
                                            attrs: {
                                              props: {
                                                label: "dictValue",
                                                value: "dictKey",
                                              },
                                              placeholder: "请选择事项来源",
                                              type: "tree",
                                              dic: _vm.HD_SOURCE_TYPE,
                                            },
                                            model: {
                                              value: _vm.dataForm.sourceType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm,
                                                  "sourceType",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm.sourceType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "现场",
                                            prop: "treeData",
                                          },
                                        },
                                        [
                                          _vm.formType != "view"
                                            ? _c(
                                                "el-upload",
                                                {
                                                  ref: "uploadRef",
                                                  staticClass: "upload-demo",
                                                  staticStyle: {
                                                    "margin-bottom": "12px",
                                                    display: "flex",
                                                    "align-items": "center",
                                                  },
                                                  attrs: {
                                                    accept: ".jpg,.mp4,.png",
                                                    action:
                                                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km",
                                                    "show-file-list": false,
                                                    "before-upload":
                                                      _vm.checkFileType,
                                                    "on-success":
                                                      _vm.handleAvatarSuccess,
                                                    headers: _vm.headers,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "small",
                                                        type: "primary",
                                                        icon: "el-icon-upload",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "点击上传\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "content" },
                                            _vm._l(
                                              _vm.treeData,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    staticClass: "img-content",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getNodeClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: item.thumbnailLink,
                                                        alt: "",
                                                      },
                                                    }),
                                                    _vm.formType !== "view"
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-close delete-icon",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.handleRemove(
                                                                item,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.unassess
            ? _c(
                "div",
                {},
                [
                  _c("head-layout", { attrs: { "head-title": "事项评估" } }),
                  _c("div", { staticClass: "formContentBox" }, [
                    _c("div", { staticClass: "formMain" }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 12px" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "dataForm",
                              attrs: {
                                model: _vm.dataForm1,
                                "label-width": "150px",
                                disabled: "",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "事项核实",
                                            prop: "passFlag",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: "y" },
                                              model: {
                                                value: _vm.dataForm1.passFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm1,
                                                    "passFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm1.passFlag",
                                              },
                                            },
                                            [_vm._v("核实通过")]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: "n" },
                                              model: {
                                                value: _vm.dataForm1.passFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm1,
                                                    "passFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm1.passFlag",
                                              },
                                            },
                                            [_vm._v("核实不通过")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.dataForm1.passFlag == "n" ||
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "评估时间",
                                                prop: "assessmentTime",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm:ss",
                                                  placeholder: "请选择评估时间",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1
                                                      .assessmentTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "assessmentTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.assessmentTime",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "责任部门",
                                                prop: "respDeptName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请选择责任部门",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1.respDeptName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "respDeptName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.respDeptName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "责任人",
                                                prop: "respUserName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请选择责任人",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1.respUserName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "respUserName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.respUserName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "提交整改计划",
                                            prop: "rectifyPlanFlag",
                                          },
                                        },
                                        [
                                          _c("el-checkbox", {
                                            attrs: {
                                              "false-label": "n",
                                              "true-label": "y",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm1.rectifyPlanFlag,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm1,
                                                  "rectifyPlanFlag",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm1.rectifyPlanFlag",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "整改部门",
                                                prop: "rectifyDeptName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请选择整改部门",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1
                                                      .rectifyDeptName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "rectifyDeptName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.rectifyDeptName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "整改人",
                                                prop: "sourceType",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder:
                                                    "请选择实际整改人",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1
                                                      .rectifyUserName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "rectifyUserName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.rectifyUserName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "验收部门",
                                                prop: "acceptDeptName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请选择验收部门",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1
                                                      .acceptDeptName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "acceptDeptName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.acceptDeptName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "验收人",
                                                prop: "acceptUserName",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请选择验收人",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1
                                                      .acceptUserName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "acceptUserName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.acceptUserName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "整改期限",
                                                prop: "rectifyLimitDate",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm:ss",
                                                  placeholder: "请选择整改期限",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1
                                                      .rectifyLimitDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "rectifyLimitDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.rectifyLimitDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _vm.dataForm1.passFlag == "y"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "整改要求",
                                                prop: "rectifyRequest",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  type: "textarea",
                                                  autosize: { minRows: 2 },
                                                  "show-word-limit": "",
                                                  placeholder: "请填写整改要求",
                                                },
                                                model: {
                                                  value:
                                                    _vm.dataForm1
                                                      .rectifyRequest,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.dataForm1,
                                                      "rectifyRequest",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dataForm1.rectifyRequest",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.unrectify
            ? _c(
                "div",
                {},
                [
                  _c("head-layout", { attrs: { "head-title": "事项整改" } }),
                  _c("div", { staticClass: "formContentBox" }, [
                    _c("div", { staticClass: "formMain" }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 12px" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "dataForm",
                              attrs: {
                                model: _vm.dataForm2,
                                "label-width": "150px",
                                disabled: "",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "完成时间",
                                            prop: "rectifyDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              placeholder: "请选择完成时间",
                                            },
                                            model: {
                                              value: _vm.dataForm2.rectifyDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm2,
                                                  "rectifyDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm2.rectifyDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "实际整改人",
                                            prop: "actualRectifyUserName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请选择实际整改人",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm2
                                                  .actualRectifyUserName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm2,
                                                  "actualRectifyUserName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm2.actualRectifyUserName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "整改措施",
                                            prop: "remark",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: { minRows: 2 },
                                              "show-word-limit": "",
                                              placeholder: "请填写整改要求",
                                            },
                                            model: {
                                              value: _vm.dataForm2.remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm2,
                                                  "remark",
                                                  $$v
                                                )
                                              },
                                              expression: "dataForm2.remark",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "整改计划",
                                            prop: "prjAs",
                                          },
                                        },
                                        [
                                          _c("el-upload", {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              "on-preview": _vm.handlePreview,
                                              disabled: "",
                                              accept:
                                                ".rar,.zip,.doc,.docx,.pdf",
                                              "file-list": _vm.treeData3,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "整改结果",
                                            prop: "prjAs",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "content" },
                                            _vm._l(
                                              _vm.treeData4,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    staticClass: "img-content",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getNodeClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    item.extension == "jpg" ||
                                                    item.extension == "png" ||
                                                    item.extension ==
                                                      "image/jpeg" ||
                                                    item.extension ==
                                                      "image/png"
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: item.link,
                                                            alt: "",
                                                          },
                                                        })
                                                      : _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/images/dashboard-nodata.png"),
                                                            alt: "",
                                                          },
                                                        }),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.finished
            ? _c(
                "div",
                {},
                [
                  _c("head-layout", { attrs: { "head-title": "事项验收" } }),
                  _c("div", { staticClass: "formContentBox" }, [
                    _c("div", { staticClass: "formMain" }, [
                      _c(
                        "div",
                        { staticStyle: { padding: "0 12px" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "dataForm3",
                              attrs: {
                                model: _vm.dataForm3,
                                "label-width": "150px",
                                disabled: "",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "验收结果",
                                            prop: "acceptFlag",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: "y" },
                                              model: {
                                                value: _vm.dataForm3.acceptFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm3,
                                                    "acceptFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm3.acceptFlag",
                                              },
                                            },
                                            [_vm._v("核实通过")]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: "n" },
                                              model: {
                                                value: _vm.dataForm3.acceptFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.dataForm3,
                                                    "acceptFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "dataForm3.acceptFlag",
                                              },
                                            },
                                            [_vm._v("核实不通过")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "验收时间",
                                            prop: "acceptDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              placeholder: "请选择完成时间",
                                            },
                                            model: {
                                              value: _vm.dataForm3.acceptDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm3,
                                                  "acceptDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm3.acceptDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "实际验收人",
                                            prop: "actualAcceptUserName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请选择实际验收人",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm3
                                                  .actualAcceptUserName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm3,
                                                  "actualAcceptUserName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm3.actualAcceptUserName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "验收参与人",
                                            prop: "joinUserName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请选择验收参与人",
                                            },
                                            model: {
                                              value: _vm.dataForm3.joinUserName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm3,
                                                  "joinUserName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm3.joinUserName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "验收意见",
                                            prop: "acceptOpinion",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              autosize: { minRows: 2 },
                                              "show-word-limit": "",
                                              placeholder: "请填写验收意见",
                                            },
                                            model: {
                                              value:
                                                _vm.dataForm3.acceptOpinion,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataForm3,
                                                  "acceptOpinion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataForm3.acceptOpinion",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "验收现场",
                                            prop: "prjAs",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "content" },
                                            _vm._l(
                                              _vm.treeData5,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: item.id,
                                                    staticClass: "img-content",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getNodeClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: item.thumbnailLink,
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择位置",
            "append-to-body": false,
            visible: _vm.mapBox,
            top: "7vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.mapBox = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _vm.mapBox
                ? _c("GaodeMap", {
                    ref: "GaodeMap",
                    on: {
                      selectAddress: _vm.selectAddress,
                      getLocation: _vm.getLocation,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.celMap } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleMapSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "部门选择", visible: _vm.deptShow1, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }